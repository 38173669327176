import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, GridProps, Stack } from '@mui/material'
import { isNil } from 'ramda'
import { Nil, RoleName, Text, Utils } from '@pbt/pbt-ui-components'
import { findConstantIdByName } from '@pbt/pbt-ui-components/src/utils'

import { AutoshipInfo } from '~/components/dashboard/prescription/elements/AutoshipInfo/AutoshipInfo'
import { AutoshipFrequencyOption } from '~/constants/autoship'
import FeatureToggle from '~/constants/featureToggle'
import { fetchMembersForSelectList } from '~/store/actions/members'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { useGetVariationDescriptionString } from '~/store/hooks/orders'
import {
  useGetApprovalOnBehalfOfString,
  useGetPrescriptionStateType,
} from '~/store/hooks/prescription'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getAutoshipUnit, getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { Prescription, Variation } from '~/types'
import { getIsCreatedPrescriptionChewyActiveRx } from '~/utils/prescription'

import ChewyPrescriptionPdfButton from './ChewyPrescriptionPdfButton'
import ChewyPrescriptionStateLabel from './prescription-editor/ChewyPrescriptionStateLabel'
import PrescriptionChewyOrderInfo from './prescription-editor/PrescriptionChewyOrderInfo'
import PrescriptionDate from './prescription-editor/PrescriptionDate'
import PrescriptionDetails from './prescription-editor/PrescriptionDetails'
import PrescriptionHeader from './prescription-editor/PrescriptionHeader'

interface ChewyPrescriptionReadOnlyProps extends GridProps {
  clientId: string | Nil
  isChewyReactiveRxReadOnly: boolean
  patientId: string
  prescription: Prescription
}

const ChewyPrescriptionReadOnly = ({
  clientId,
  isChewyReactiveRxReadOnly,
  prescription,
  patientId,
  ...props
}: ChewyPrescriptionReadOnlyProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Dialogs', 'Common'])

  const doctor = useSelector(getUser(prescription.doctorId))

  const mainStaffRoles = useMainStaffRoles()
  const isChewyActiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ACTIVE_RX),
  )

  const drugDescription = useGetVariationDescriptionString(
    prescription.drugInfo as Variation,
    prescription?.drugInfo?.flavor,
  )

  const prescriptionSignerId = prescription.signerId || prescription.signer?.id
  const prescriptionDoctor = prescription.doctor || doctor

  const approvalOnBehalfOfString = useGetApprovalOnBehalfOfString({
    doctor: prescriptionDoctor,
    signerId: prescriptionSignerId,
  })

  const { isApproved } = useGetPrescriptionStateType()(prescription.stateId)
  const isChewyActiveRx = getIsCreatedPrescriptionChewyActiveRx(
    prescription.prescriptionType,
    prescription.origin,
  )

  const AutoshipUnit = useSelector(getAutoshipUnit)
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const isChewyCheckoutEnabledAndHasAutoship =
    isChewyCheckoutEnabled &&
    !isNil(prescription?.retailOrderLineItem?.autoshipUnitId) &&
    !isNil(prescription?.retailOrderLineItem?.autoshipFrequency) &&
    isChewyActiveRx

  const chewyAuthorizationRoleId = findConstantIdByName(
    RoleName.ChewyAuthorization,
    mainStaffRoles,
  )
  const doctorRoleId = findConstantIdByName(
    RoleName.Veterinarian,
    mainStaffRoles,
  )
  const doctorRoleId2 = findConstantIdByName(
    RoleName.Veterinarian2,
    mainStaffRoles,
  )

  const roleIds = useMemo(
    () =>
      [chewyAuthorizationRoleId, doctorRoleId, doctorRoleId2].filter(Boolean),
    [chewyAuthorizationRoleId, doctorRoleId, doctorRoleId2],
  )

  // This is needed as we have submit action on chewy active RX when status is approved, and
  // we need to validate fields (including doctorId which has a custom validator on usePrescriptionEditor)
  useEffect(() => {
    if (isChewyActiveRx && isChewyActiveRxEnabled && isApproved) {
      dispatch(
        fetchMembersForSelectList({
          from: 0,
          to: 100,
          includeInactive: false,
          shorten: true,
          roleIds,
          noRolesInResult: true,
        }),
      )
    }
  }, [isApproved, isChewyActiveRx, isChewyActiveRxEnabled, roleIds])

  return (
    <Grid display="flex" flexDirection="column" gap={2} {...props}>
      {isChewyReactiveRxReadOnly && (
        <>
          <PrescriptionHeader
            showTitlePrefix
            clientId={clientId}
            patientId={patientId}
            prescription={prescription}
          />
          <Grid>
            <PrescriptionDate
              date={prescription.createdAt || prescription.creationDate}
              prefix={`${t('Common:CREATED_ON')}:`}
            />
            <PrescriptionDate
              date={prescription.updatedAt || prescription.modificationDate}
              prefix={`${t('Common:UPDATED_ON')}:`}
            />
          </Grid>
          {prescription?.stateId && (
            <Grid container>
              <ChewyPrescriptionStateLabel prescription={prescription} />
            </Grid>
          )}
        </>
      )}
      {prescriptionDoctor && (
        <Grid>
          <Text strong variant="body">
            {approvalOnBehalfOfString}
          </Text>
        </Grid>
      )}
      <PrescriptionDetails
        showDispensed
        showRefills
        ContainerProps={{ spacing: 0, mt: -3 }}
        prescription={prescription}
      />
      {drugDescription && (
        <Grid>
          <Text strong variant="subheading3">
            {t('Dialogs:PRESCRIPTION_DIALOG.DRUG_INFO')}
          </Text>
          <Text variant="body2">{drugDescription}</Text>
        </Grid>
      )}
      {isChewyActiveRx ? (
        <Stack direction="row" gap={2}>
          <PrescriptionChewyOrderInfo prescription={prescription} />
          {isChewyCheckoutEnabledAndHasAutoship &&
            prescription.retailOrderLineItem && (
              <AutoshipInfo
                frequency={
                  {
                    uom: Utils.findById(
                      prescription.retailOrderLineItem.autoshipUnitId,
                      AutoshipUnit,
                    ),
                    value: prescription.retailOrderLineItem.autoshipFrequency,
                  } as AutoshipFrequencyOption
                }
              />
            )}
        </Stack>
      ) : (
        <Grid>
          <PrescriptionChewyOrderInfo prescription={prescription} />
          <Text variant="body2">
            <ChewyPrescriptionPdfButton pdfUrl={prescription.pdfUrl} />
          </Text>
        </Grid>
      )}
    </Grid>
  )
}

export default ChewyPrescriptionReadOnly
