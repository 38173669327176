import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import { Invoice as GraphqlInvoice } from '~/api/graphql/generated/types'
import { StaticTableColumn } from '~/components/common/lists/table/StaticTable'

import { ClientBillingActivityTable } from '../common/ClientBillingActivityTable'
import { useGetInvoiceBillingActivityTableColumns } from './columns'

interface InvoiceBillingActivityTableProps {
  invoices: GraphqlInvoice[]
  invoicesPerPage: number
  isLoading: boolean
  onExpand: () => void
}

const multipleRowsLimit = 2

export const InvoiceBillingActivityTable = ({
  invoices: invoicesProp,
  invoicesPerPage,
  isLoading,
  onExpand,
}: InvoiceBillingActivityTableProps) => {
  const columns = useGetInvoiceBillingActivityTableColumns({
    key: 'prop',
    multipleRowsLimit,
  }) as StaticTableColumn<GraphqlInvoice>[]
  const [invoices, setInvoices] = useState<GraphqlInvoice[]>()

  useEffect(() => {
    if (invoicesProp) {
      const invoicesWithExpandableSection = invoicesProp.reduce(
        (invoicesArray: GraphqlInvoice[], invoice: GraphqlInvoice) => {
          if (R.isNil(invoice)) {
            return invoicesArray
          }
          if (invoice.sections?.length > multipleRowsLimit) {
            invoicesArray.push(invoice)
            invoicesArray.push({
              id: `skip-${uuid()}`,
              sections: invoice.sections,
              onExpand,
            } as unknown as GraphqlInvoice)
          } else {
            invoicesArray.push(invoice)
          }
          return invoicesArray
        },
        [],
      )
      setInvoices(invoicesWithExpandableSection)
    }
  }, [invoicesProp])

  return (
    <ClientBillingActivityTable
      columns={columns}
      isLoading={isLoading}
      itemsPerPage={invoicesPerPage}
      rowItems={invoices}
    />
  )
}
