import React, { useState } from 'react'
import { KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material'
import {
  Collapse,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    cell: {
      borderBottom: 'none',
    },
    collapse: {
      paddingLeft: theme.spacing(3),
    },
    totalCell: {
      borderBottom: 'none',
      alignItems: 'center',
    },
    root: {
      '& .MuiTableCell-root': {
        padding: 0,
      },
    },
  }),
  { name: 'FinanceDetailedFooterTable' },
)

type TotalRowProps = {
  name: (isExpanded: boolean) => string
  value: string | 0 | undefined
}

type RawDataProps = {
  Component?: (props: any) => JSX.Element | null
  id: string
  name?: string
  value?: string | 0 | undefined
}

interface FinanceDetailedFooterTableProps {
  id?: string
  isGlobalFooter?: boolean
  rowData: RawDataProps[]
  totalRowData: TotalRowProps
}

const FinanceDetailedFooterTable = ({
  isGlobalFooter,
  id: sectionId,
  rowData = [],
  totalRowData,
}: FinanceDetailedFooterTableProps) => {
  const classes = useStyles()

  const [totalExpanded, setTotalExpanded] = useState(false)

  type RowDataRendererProps = {
    Component?: any
    id: string
    name?: string
    value?: string | 0 | undefined
  }

  const rowDataRenderer = ({
    id,
    name,
    value,
    Component,
  }: RowDataRendererProps) =>
    (Component && (
      <Component
        isGlobalFooter={isGlobalFooter}
        key={id}
        sectionId={sectionId}
      />
    )) || (
      <Grid
        container
        justifyContent="space-between"
        key={id}
        pr={!isGlobalFooter ? 3 : 0}
      >
        <Text variant="body2">{name}</Text>
        <Text align="right" variant="body2">
          {value}
        </Text>
      </Grid>
    )

  return (
    <Table aria-label="collapsible table" className={classes.root}>
      <TableBody>
        <TableRow>
          <TableCell className={classes.cell} colSpan={2}>
            <Collapse className={classes.collapse} in={totalExpanded}>
              {rowData.map(rowDataRenderer)}
            </Collapse>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ border: 'none' }}>
            <Grid
              container
              justifyContent="space-between"
              pr={!isGlobalFooter ? 3 : 0}
              onClick={() => setTotalExpanded(!totalExpanded)}
            >
              <Grid container item md={8} sm={8} wrap="nowrap" xl={8} xs={8}>
                {totalExpanded ? <KeyboardArrowUp /> : <KeyboardArrowRight />}
                <Text strong variant="body2">
                  {totalRowData.name(totalExpanded)}
                </Text>
              </Grid>
              <Text inline strong variant="body2">
                {totalExpanded && totalRowData.value}
              </Text>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default FinanceDetailedFooterTable
