/* eslint-disable react/no-unstable-nested-components */
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'

import { CollapsibleInformationList } from '~/components/elements/CollapsibleInformationList/CollapsibleInformationList'
import Typography from '~/components/elements/Typography/Typography'
import { FINANCE_TABLE_PADDING_X_SPACING_VALUE } from '~/constants/financeTable'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { useGetInvoiceV3Items } from '~/store/hooks/invoiceV3'
import {
  getInvoiceV3,
  getInvoiceV3Loading,
  useIsAnyPrepaid,
} from '~/store/reducers/invoiceV3'

import InvoiceStatusLabel from '../../invoices/InvoiceStatusLabel'
import { LineItemComponentsActions } from '../../soap/rail/summary/orders/orderSummaryUtils'
import {
  INVOICE_COLUMNS,
  INVOICE_COLUMNS_WITH_REFUND,
} from '../../soapV2/charges/table/Columns'
import SoapWidget from '../../soapV2/SoapWidget'
import ChargesPanel from '../components/ChargesPanel'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTableSubHeader from './InvoiceTableSubHeader'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      margin: theme.spacing(0, 1, 1.5),
      width: 'unset',
    },
    tableTitle: {
      color: theme.colors.tabLabel,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.4rem',
      height: theme.spacing(4),
      padding: theme.spacing(0, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: ({ useInvoiceCVCLayout }: UseStylesProps) =>
          theme.spacing(
            FINANCE_TABLE_PADDING_X_SPACING_VALUE +
              (useInvoiceCVCLayout ? -1 : 1.5),
          ),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    chargeInformationPanel: {
      top: theme.mixins.toolbar.minHeight,
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
    tableSubTitle: {
      padding: theme.spacing(0.5, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: ({ useInvoiceCVCLayout }: UseStylesProps) =>
          theme.spacing(
            FINANCE_TABLE_PADDING_X_SPACING_VALUE +
              (useInvoiceCVCLayout ? -1 : 1.5),
          ),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
  }),
  { name: 'ViewInvoice' },
)

interface InvoiceDetailsProps {
  useInvoiceCVCLayout: boolean
}

interface UseStylesProps {
  useInvoiceCVCLayout: boolean
}

export const InvoiceDetails = ({
  useInvoiceCVCLayout,
}: InvoiceDetailsProps) => {
  const classes = useStyles({ useInvoiceCVCLayout })
  const { invoiceId } = useParams()
  const { t } = useTranslation(['Common', 'Invoices'])

  const isInvoiceLoading = useSelector(getInvoiceV3Loading)
  const invoice = useSelector(getInvoiceV3(invoiceId))
  const {
    additionalDiscount = 0,
    refunds,
    subtotal,
    totalDiscount = 0,
    totalTax,
    amountNoFee,
  } = invoice || {}

  const hasRefunds = !R.isEmpty(refunds || [])

  const items = useGetInvoiceV3Items(invoiceId)
  const showPaidUsedRemainingLabel = useIsAnyPrepaid()
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const columns = hasRefunds ? INVOICE_COLUMNS_WITH_REFUND : INVOICE_COLUMNS

  const InvoiceWrapper = useInvoiceCVCLayout ? SoapWidget : Fragment
  const InvoiceWrapperProps = useInvoiceCVCLayout
    ? {
        title: (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Typography.H2 color="brandPrimary">
              {t('Common:CHEWY_VET_CARE')}
            </Typography.H2>
            {isInvoiceLoading ? (
              <Skeleton variant="text" width={32} />
            ) : (
              <InvoiceStatusLabel
                fontSize="1.2rem"
                invoice={invoice!}
                variant="default"
              />
            )}
          </Box>
        ),
        classes: {
          container: classes.container,
        },
      }
    : {}

  if (!isInvoiceLoading && R.isEmpty(items) && isChewyCheckoutEnabled) {
    return null
  }

  return (
    <InvoiceWrapper {...(InvoiceWrapperProps as any)}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ label, width, id }) => (
              <TableCell
                className={classNames(classes.tableTitle)}
                key={id}
                width={width}
              >
                <Text inline strong variant="lowAccent2">
                  {label}
                </Text>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {columns.map(({ subLabels, width, id }) => (
              <TableCell
                className={classes.tableSubTitle}
                key={id}
                width={width}
              >
                {showPaidUsedRemainingLabel && subLabels && (
                  <Text variant="lowAccent5">
                    {subLabels.map((subLabel) => subLabel.label).join(' | ')}
                  </Text>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      <Grid container item>
        <Grid container item alignItems="center" justifyContent="center">
          {isInvoiceLoading ? (
            <CircularProgress color="primary" size={32} />
          ) : (
            <>
              <ChargesPanel
                isInvoice
                ComponentsActions={LineItemComponentsActions}
                Footer={(props) => (
                  <InvoiceTableFooter
                    {...props}
                    useInvoiceCVCLayout={useInvoiceCVCLayout}
                  />
                )}
                SubHeader={(props) => (
                  <InvoiceTableSubHeader
                    {...props}
                    colSpan={refunds?.length ? 7 : 6}
                    useInvoiceCVCLayout={useInvoiceCVCLayout}
                  />
                )}
                chargesGroupedItems={items}
                chargesId={invoiceId}
                classes={{
                  chargeInformationPanel: classes.chargeInformationPanel,
                }}
                hasHeader={false}
                invoiceId={invoiceId}
              />
              {useInvoiceCVCLayout && (
                <Box display="flex" px={2} py={1} width="100%">
                  <Typography.Paragraph color="grayGray2">
                    {t('Invoices:INVOICE_FOOTNOTE')}
                  </Typography.Paragraph>
                  <CollapsibleInformationList
                    initiallyOpened
                    // width to dynamically align with invoice footer
                    ContainerProps={{
                      ml: 'auto',
                      width: 'calc(23.75% - 6px)',
                    }}
                    list={[
                      {
                        id: 'subtotal',
                        label: t('Common:SUBTOTAL'),
                        value: NumberUtils.formatMoney(subtotal),
                      },
                      {
                        id: 'discount',
                        label: t('Common:DISCOUNT'),
                        value: NumberUtils.formatMoney(
                          totalDiscount + additionalDiscount,
                        ),
                      },
                      {
                        id: 'tax',
                        label: t('Common:TAX'),
                        value: NumberUtils.formatMoney(totalTax),
                      },
                    ]}
                    title={t('Common:INVOICE_TOTAL')}
                    titleValue={NumberUtils.formatMoney(amountNoFee)}
                  />
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </InvoiceWrapper>
  )
}
