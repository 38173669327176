import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateUtils,
  moment,
  Nil,
  NumberUtils,
  TextWithTooltip,
  Utils,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'
import { formatMoney } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import {
  Payment as GraphQlPayment,
  RetailOrder,
  RetailPaymentDetail,
  Totals,
} from '~/api/graphql/generated/types'
import MenuDropdown from '~/components/common/inputs/MenuDropdown'
import { CollapsibleInformationList } from '~/components/elements/CollapsibleInformationList/CollapsibleInformationList'
import Typography from '~/components/elements/Typography/Typography'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import i18nPortal from '~/locales/i18n'
import {
  fetchClientBillingActivity,
  getBillingActivitySummary,
} from '~/store/duck/clientBillingActivityData'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import {
  BatchInvoice,
  InvoiceOrEstimate,
  InvoiceTotals,
  Payment,
} from '~/types'
import useDialog from '~/utils/useDialog'

import { ChewyPaymentTile } from './ChewyPaymentTile'
import { InvoicePaymentTile } from './InvoicePaymentTile'
import {
  formatMoneyRange,
  formatPositiveMoney,
  getIsBatchInvoice,
} from './invoiceUtils'

export const useInvoiceTotalsSectionStyles = makeStyles(
  (theme) => ({
    totalContainer: {
      backgroundColor: theme.colors.tableLeftColumnBackground,
      borderLeft: theme.constants.tabBorder,
    },
    row: {
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.totalBorder,
      },
    },
    leftColumn: {
      borderRight: theme.constants.totalBorder,
    },
    expander: {
      cursor: 'pointer',
    },
    collapse: {
      paddingLeft: theme.spacing(3),
    },
    menuDropdownButton: {
      fontWeight: 500,
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'left',
    },
    iconButton: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'InvoiceTotalsSection' },
)

const MINIMUM_AMOUNT_OF_PAYMENTS_TO_COLLAPSE = 3

const ServiceFeeOptions = [
  {
    name: i18nPortal.t('Invoices:WITH_SERVICE_FEE'),
    value: true,
  },
  {
    name: i18nPortal.t('Invoices:WITHOUT_SERVICE_FEE'),
    value: false,
  },
]

export interface InvoiceTotalsSectionProps {
  clientId: string | Nil
  includeServiceFee: boolean
  invoice: (InvoiceOrEstimate | BatchInvoice) & {
    grandTotal?: Totals
    retailOrder?: RetailOrder
  }
  isEstimate: boolean
  navigateToRefundLanding?: () => void
  setIncludeServiceFee: (arg: boolean) => void
  setInvoice: (invoice: InvoiceOrEstimate) => void
  totals: InvoiceTotals
}

const InvoiceTotalsSection = ({
  clientId,
  invoice,
  setInvoice,
  isEstimate,
  includeServiceFee,
  setIncludeServiceFee,
  navigateToRefundLanding,
  totals,
}: InvoiceTotalsSectionProps) => {
  const classes = useInvoiceTotalsSectionStyles()
  const dispatch = useDispatch()
  const hasServiceFee = Boolean(invoice.serviceFee)
  const { t } = useTranslation(['Common', 'Invoices'])

  const client = useSelector(getUser(clientId))
  const clientBillingActivitySummary = useSelector(getBillingActivitySummary)
  const isDiscountReasonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DISCOUNT_REASON),
  )

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const [openRequiredDepositDialog] = useDialog(DialogNames.REQUIRED_DEPOSIT)

  const [isPaymentsCollapsed, setIsPaymentsCollapsed] = useState(false)

  const { payments: invoicePayments = [], retailOrder, grandTotal } = invoice
  const isChewyCheckoutEnabledAndHasRetailOrder =
    isChewyCheckoutEnabled && retailOrder
  const hasDeposit = isEstimate && !R.isNil(invoice.requiredDeposit)
  const deposit = includeServiceFee
    ? totals.requiredDeposit
    : totals.requiredDepositWithoutFee

  const totalAmountNoFee = !isEstimate
    ? R.prop('dueToPayNoFee', invoice)
    : R.prop('totalWithoutFee', totals)
  const totalAmountFee = !isEstimate
    ? R.prop('dueToPay', invoice)
    : R.prop('total', totals)
  const totalAmount = includeServiceFee ? totalAmountFee : totalAmountNoFee

  const chewyPayments = retailOrder?.paymentDetails || []
  const hasChewyPayments =
    isChewyCheckoutEnabledAndHasRetailOrder && !R.isEmpty(chewyPayments)
  const completedPayments = useMemo(
    () =>
      hasChewyPayments
        ? [...invoicePayments, ...chewyPayments]
        : invoicePayments,
    [hasChewyPayments, invoicePayments?.length, chewyPayments?.length],
  )
  const showTotalPaidLabel =
    completedPayments.length >= MINIMUM_AMOUNT_OF_PAYMENTS_TO_COLLAPSE
  const isBatchInvoice = getIsBatchInvoice(invoice)

  const showServiceFeeOptions = invoice.dueToPayNoFee !== 0 && hasServiceFee

  const isChewyPayment = (
    payment: GraphQlPayment | RetailPaymentDetail | Payment,
  ): payment is RetailPaymentDetail =>
    '__typename' in payment && payment.__typename === 'RetailPaymentDetail'

  const overageAmount = completedPayments.reduce(
    (totalOverage, currentPayment) => {
      if (isChewyPayment(currentPayment)) {
        return totalOverage
      }

      return currentPayment.split &&
        currentPayment.splitAmount &&
        currentPayment.amount
        ? totalOverage + (currentPayment.amount - currentPayment.splitAmount)
        : totalOverage
    },
    0,
  )

  const showClientBalance = invoice.paid
    ? invoice.paidDate && invoice.balanceAfterPaid
    : true
  const clientBalanceDate = invoice.paid
    ? invoice.paidDate
    : moment().toDate().toISOString()
  const clientBalance = invoice.paid
    ? invoice.balanceAfterPaid
    : clientBillingActivitySummary.balance

  useEffect(() => {
    if (completedPayments) {
      setIsPaymentsCollapsed(showTotalPaidLabel)
    }
  }, [completedPayments])

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientBillingActivity(clientId))
    }
  }, [clientId])

  return (
    <Grid
      container
      item
      className={classes.totalContainer}
      direction="column"
      width={isEstimate ? 500 : '100%'}
      wrap="nowrap"
    >
      <Grid item className={classes.row}>
        <CollapsibleInformationList
          ContainerProps={{ pr: 3, py: 1 }}
          border={{ position: '33.33%', color: '#E6E8E9' }}
          initiallyOpened={false}
          list={[
            ...(R.prop('showBalance', invoice) && !isEstimate
              ? [
                  {
                    id: 'previous_balance',
                    label: t('Common:PREVIOUS_BALANCE'),
                    value: NumberUtils.formatMoney(
                      R.prop('prevBalance', invoice),
                    ),
                  },
                ]
              : []),
            {
              id: 'subtotal',
              label: t('Common:SUBTOTAL'),
              value: formatMoneyRange(
                !isEstimate
                  ? isChewyCheckoutEnabledAndHasRetailOrder
                    ? grandTotal?.subTotal
                    : invoice.subtotal
                  : totals.totalWithoutTax,
              ),
            },
            {
              id: 'discount',
              label: (
                <TextWithTooltip
                  tooltipText={
                    isDiscountReasonEnabled && invoice.discountReasonName
                  }
                  variant="body2"
                >
                  {t('Common:DISCOUNT')}
                </TextWithTooltip>
              ),
              value: formatMoneyRange(
                !isEstimate
                  ? isChewyCheckoutEnabledAndHasRetailOrder
                    ? grandTotal?.discount
                    : R.prop('additionalDiscount', invoice) +
                      (R.prop('totalDiscount', invoice) || 0)
                  : R.prop('totalDiscountWithAdditional', totals),
              ),
            },
            {
              id: 'tax',
              label: t('Common:TAX'),
              value: formatMoneyRange(
                !isEstimate
                  ? isChewyCheckoutEnabledAndHasRetailOrder
                    ? grandTotal?.tax
                    : R.prop('totalTax', invoice)
                  : totals.totalTax,
              ),
            },
            ...(isChewyCheckoutEnabledAndHasRetailOrder
              ? [
                  {
                    id: 'shipping',
                    label: t('Common:SHIPPING'),
                    value: NumberUtils.formatMoney(grandTotal?.shipping),
                  },
                  {
                    id: 'retail_delivery_fee',
                    label: t('Common:RETAIL_DELIVERY_FEE'),
                    value: NumberUtils.formatMoney(
                      retailOrder.retailDeliveryFee,
                    ),
                  },
                ]
              : []),
          ]}
          title={
            isBatchInvoice
              ? t('Invoices:GRAND_TOTAL_INVOICES', {
                  invoicesLength: invoice.invoices?.length,
                })
              : isChewyCheckoutEnabled && !isEstimate
                ? t('Common:GRAND_TOTAL')
                : t('Common:INVOICE_TOTAL')
          }
          titleValue={formatMoneyRange(
            !isEstimate
              ? isChewyCheckoutEnabledAndHasRetailOrder
                ? grandTotal?.total
                : invoice.amountNoFee
              : totals.totalWithoutFee,
          )}
        />
      </Grid>
      {showServiceFeeOptions && (
        <Grid container item alignItems="center" className={classes.row}>
          <Grid container item className={classes.leftColumn} pl={3} xs={8}>
            <MenuDropdown
              linkButton
              classes={{
                button: classes.menuDropdownButton,
              }}
              items={ServiceFeeOptions}
              title={
                ServiceFeeOptions.find(
                  (option) => option.value === includeServiceFee,
                )?.name
              }
              onSelected={setIncludeServiceFee}
            />
          </Grid>
          <Grid item pr={3} xs={4}>
            <Typography.H3 sx={{ textAlign: 'right' }}>
              {formatMoneyRange(totalAmount)}
            </Typography.H3>
          </Grid>
        </Grid>
      )}
      {hasDeposit && (
        <Grid container item alignItems="center" className={classes.row}>
          <Grid
            container
            item
            alignItems="center"
            className={classes.leftColumn}
            pl={3}
            wrap="nowrap"
            xs={8}
          >
            <Typography.H3 sx={{ textAlign: 'right' }}>
              {t('Invoices:REQUIRED_DEPOSIT_AMOUNT', {
                amount: NumberUtils.toPercentFormat(invoice.requiredDeposit, 2),
              })}
            </Typography.H3>
            <IconButton
              className={classes.iconButton}
              size="large"
              onClick={() => {
                openRequiredDepositDialog({
                  estimate: invoice as InvoiceOrEstimate,
                  onChange: setInvoice,
                })
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item pr={3} xs={4}>
            <Typography.H3 sx={{ textAlign: 'right' }}>
              {formatMoneyRange(deposit)}
            </Typography.H3>
          </Grid>
        </Grid>
      )}
      {!R.isEmpty(completedPayments) && (
        <Grid className={classes.row}>
          {showTotalPaidLabel && (
            <Grid
              container
              item
              alignItems="center"
              className={classNames(classes.expander, {
                [classes.row]: !isPaymentsCollapsed,
              })}
              onClick={() => setIsPaymentsCollapsed(!isPaymentsCollapsed)}
            >
              <Grid
                container
                item
                alignItems="center"
                className={classes.leftColumn}
                py={1.5}
                wrap="nowrap"
                xs={8}
              >
                {isPaymentsCollapsed ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowUp />
                )}
                <Typography.H3>
                  {t('Invoices:TOTAL_PAID_COMPLETED_PAYMENTS', {
                    completedPayments: completedPayments.length,
                  })}
                </Typography.H3>
              </Grid>
              <Grid item pr={3} xs={4}>
                <Typography.H3 sx={{ textAlign: 'right' }}>
                  {formatMoneyRange(invoice.paidAmount, true)}
                </Typography.H3>
              </Grid>
            </Grid>
          )}
          <Collapse in={!isPaymentsCollapsed}>
            {completedPayments.map(
              (payment: RetailPaymentDetail | GraphQlPayment | Payment) => {
                if (isChewyPayment(payment)) {
                  const clientFullName = Utils.getPersonString(client)

                  return (
                    <ChewyPaymentTile
                      clientFullName={clientFullName}
                      key={payment.paymentMethod.id}
                      orderDate={retailOrder?.orderDate}
                      payment={payment}
                    />
                  )
                }

                return (
                  <InvoicePaymentTile
                    clientId={clientId}
                    invoiceId={invoice.id}
                    isEstimate={isEstimate}
                    key={payment.id}
                    navigateToRefundLanding={navigateToRefundLanding}
                    payment={payment}
                  />
                )
              },
            )}
          </Collapse>
        </Grid>
      )}
      <Grid container item alignItems="center" className={classes.row}>
        <Grid item className={classes.leftColumn} pl={3} xs={8}>
          <Typography.H3>
            {isChewyCheckoutEnabled && !isEstimate
              ? t('Invoices:CVC_INVOICE_TOTAL_DUE')
              : t('Invoices:INVOICE_TOTAL_DUE')}
          </Typography.H3>
        </Grid>
        <Grid item pr={3} xs={4}>
          <Typography.H3 sx={{ textAlign: 'right' }}>
            {formatPositiveMoney(invoice.dueToPayNoFee)}
          </Typography.H3>
        </Grid>
      </Grid>
      {overageAmount > 0 && (
        <Grid container item alignItems="center" className={classes.row}>
          <Grid item className={classes.leftColumn} pl={3} xs={8}>
            <Typography.H3>{t('Invoices:PAYMENT_OVERAGE')}</Typography.H3>
          </Grid>
          <Grid item pr={3} xs={4}>
            <Typography.H3 sx={{ textAlign: 'right' }}>
              {formatPositiveMoney(overageAmount)}
            </Typography.H3>
          </Grid>
        </Grid>
      )}
      {showClientBalance && (
        <Grid container item alignItems="center" className={classes.row}>
          <Grid item className={classes.leftColumn} pl={3} xs={8}>
            <Typography.Paragraph>
              {t('Invoices:UPDATED_ACCOUNT_BALANCE_LABEL', {
                date: DateUtils.formatDate(clientBalanceDate),
              })}
            </Typography.Paragraph>
          </Grid>
          <Grid item pr={3} xs={4}>
            <Typography.H3 sx={{ textAlign: 'right' }}>
              {formatMoney(clientBalance)}
            </Typography.H3>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default InvoiceTotalsSection
