import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'
import { formatDate } from '@pbt/pbt-ui-components/src/utils/dateUtils'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { DiscountReason, InvoiceLineItem } from '~/types/entities'
import useDialog from '~/utils/useDialog'

import { getFeatureToggle } from '../reducers/constants'
import {
  fetchInvoiceV3,
  getInvoiceV3,
  getInvoiceV3SubItemById,
  getInvoiceV3SubItemsMap,
  resetInvoiceState,
} from '../reducers/invoiceV3'
import { getPatient } from '../reducers/patients'
import { getTimetableEvent } from '../reducers/timetable'
import { getUser } from '../reducers/users'

export const useGetInvoiceV3Items = (
  invoiceId?: string,
): Record<string, InvoiceLineItem[]> => {
  const dispatch = useDispatch()

  const invoice = useSelector(getInvoiceV3(invoiceId))
  const subItemsMap = useSelector(getInvoiceV3SubItemsMap)

  const invoiceSubItemsIds: string[] = R.prop('groups', invoice) || []

  useEffect(() => {
    if (invoiceId) {
      dispatch(fetchInvoiceV3({ id: invoiceId }))
    }
    return () => {
      dispatch(resetInvoiceState())
    }
  }, [invoiceId])

  const invoiceItems = R.pipe<
    (typeof invoiceSubItemsIds)[],
    [string, InvoiceLineItem[]][],
    Record<string, InvoiceLineItem[]>,
    Record<string, InvoiceLineItem[]>
  >(
    R.map((id: string) => [
      id,
      R.prop('groupedItems', subItemsMap[id])! as InvoiceLineItem[],
    ]),
    R.fromPairs,
    R.filter(Boolean),
  )(invoiceSubItemsIds)

  return invoiceItems
}

export const useGetInvoiceV3ItemHeader = (currentItemId?: string) => {
  const { t } = useTranslation(['Common', 'Abbreviations'])

  const currentItem = useSelector(getInvoiceV3SubItemById(currentItemId))
  const event = useSelector(getTimetableEvent(currentItem?.eventId))
  const patient = useSelector(getPatient(currentItem?.patientId))
  const vet = useSelector(getUser(currentItem?.soap?.assignedVetId))

  if (R.isNil(currentItem) || R.isNil(patient)) {
    return ''
  }

  const itemName =
    event?.businessAppointmentType?.name ||
    t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.LABEL_ABBREVIATION')

  const patientName = patient ? R.prop('name', patient) : ''

  const vetName = Utils.getPersonString(vet)

  const date = formatDate(event?.scheduledStartDatetime)

  const formattedPatientName = patientName
    ? `${t('Common:FOR')} ${patientName}`
    : ''
  const formattedVetName = vetName
    ? `${t('Common:WITH').toLowerCase()} ${vetName}`
    : ''

  return `${itemName} ${formattedPatientName} ${formattedVetName} ${date}`
}

const useStyles = makeStyles(
  (theme) => ({
    dialogContainer: {
      color: theme.colors.secondaryText,
      fontSize: '1.2rem',
    },
  }),
  { name: 'useConfirmAlertForPostingInvoice' },
)

export interface PostInvoiceAdditionalInput {
  discountReason?: DiscountReason
}

export const useConfirmAlertForPostingInvoice = (
  onPostInvoice: (
    postInvoiceAdditionalInput: PostInvoiceAdditionalInput,
  ) => void,
) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs', 'Invoices'])

  const [isRedirectToInvoice, setIsRedirectToInvoice] = useState(false)

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.POST_INVOICE,
  })

  const openPostInvoiceConfirmAlert = (
    postInvoiceAdditionalInput: PostInvoiceAdditionalInput,
  ) => {
    openConfirmAlert({
      applyCustomMessage: true,
      message: t('Invoices:POST_CONFIRM_MESSAGE'),
      content: t('Invoices:POST_CONFIRM_CONTENT'),
      okButtonText: t('Common:YES_POST_INVOICE'),
      cancelButtonText: t(
        'Dialogs:CONFIRM_ALERT_DIALOG.POST_INVOICE.CANCEL_BUTTON',
      ),
      classes: { container: classes.dialogContainer },
      onConfirm: (proceed: boolean) => {
        if (proceed) {
          setIsRedirectToInvoice(true)
          onPostInvoice(postInvoiceAdditionalInput)
        }
      },
    })
  }

  return { isRedirectToInvoice, openPostInvoiceConfirmAlert }
}

interface UseAddDiscountReasonDialogProps {
  discounts?: number
  onOk: (selectedDiscountReason?: DiscountReason) => void
}

export const useAddDiscountReasonDialog = ({
  onOk,
  discounts,
}: UseAddDiscountReasonDialogProps) => {
  const isDiscountReasonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DISCOUNT_REASON),
  )
  const [openInvoiceDiscountReasonDialog, closeInvoiceDiscountReasonDialog] =
    useDialog(DialogNames.INVOICE_DISCOUNT_REASON)

  const openDiscountReasonDialog = () =>
    isDiscountReasonEnabled && Boolean(discounts)
      ? openInvoiceDiscountReasonDialog({
          onOk: (selectedDiscountReason) => {
            closeInvoiceDiscountReasonDialog()
            onOk(selectedDiscountReason)
          },
        })
      : onOk()

  return { openDiscountReasonDialog }
}
