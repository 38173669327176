import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { NumberUtils } from '@pbt/pbt-ui-components'
import { ChewySubmark } from '@pbt/pbt-ui-components/src/icons'

import { CollapsibleInformationList } from '~/components/elements/CollapsibleInformationList/CollapsibleInformationList'
import Typography from '~/components/elements/Typography/Typography'
import { RetailOrderStates } from '~/constants/invoice'
import { useGetChewyOrderState } from '~/store/hooks/retailOrderItems'
import { getInvoiceV3, getInvoiceV3Loading } from '~/store/reducers/invoiceV3'

import { ChewyRetailOrderStatusLabel } from '../../invoices/elements/ChewyRetailOrderStatusLabel/ChewyRetailOrderStatusLabel'
import { LineItemComponentsActions } from '../../soap/rail/summary/orders/orderSummaryUtils'
import SoapWidget from '../../soapV2/SoapWidget'
import ChargesPanel from '../components/ChargesPanel'
import InvoiceTableSubHeader, {
  InvoiceTableSubHeaderProps,
} from './InvoiceTableSubHeader'

const useStyles = makeStyles(
  (theme) => ({
    chargeInformationPanel: {
      top: theme.mixins.toolbar.minHeight,
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
    container: {
      margin: theme.spacing(1.5, 1),
      width: 'unset',
    },
    content: {
      borderTop: 0,
    },
    title: {
      padding: theme.spacing(0.5, 1),
    },
  }),
  { name: 'InvoiceChewyItemsSection' },
)

const SubHeader = (props: InvoiceTableSubHeaderProps) => (
  <InvoiceTableSubHeader {...props} useInvoiceCVCLayout />
)

// eslint-disable-next-line react/no-multi-comp
export const InvoiceChewyItemsSection = () => {
  const classes = useStyles()
  const { invoiceId } = useParams()
  const { t } = useTranslation(['Common', 'Invoices'])

  const isLoading = useSelector(getInvoiceV3Loading)
  const invoice = useSelector(getInvoiceV3(invoiceId))

  const retailOrder = invoice?.retailOrder
  const {
    discountAmount,
    retailDeliveryFee,
    sections,
    shippingAmount,
    state,
    subTotal,
    taxAmount,
    totalPrice,
  } = retailOrder || {}
  const chewyItems = sections?.reduce((acc, section) => {
    if (section.id) {
      return {
        [section.id]: section.groupedItems,
      }
    }
    return acc
  }, {})

  const statusId = state?.id
  const orderNumber = retailOrder?.orderConfirmationId
  const { currentState, isOrderPlaced } = useGetChewyOrderState(statusId)
  const title = isOrderPlaced
    ? t('Invoices:CHEWY_ITEMS.TITLE_WITH_ORDER', { orderNumber })
    : t('Invoices:CHEWY_ITEMS.TITLE')
  const descriptionMap = {
    [RetailOrderStates.DRAFT]: t('Invoices:CHEWY_ITEMS.DRAFT_INFORMATION'),
    [RetailOrderStates.MOVED_TO_CHEWY_CART]: t(
      'Invoices:CHEWY_ITEMS.CHEWY_CART_INFORMATION',
    ),
    [RetailOrderStates.ORDER_PLACED]: t(
      'Invoices:CHEWY_ITEMS.ORDER_PLACED_INFORMATION',
    ),
  }
  const description = descriptionMap[currentState?.name]

  if (isLoading || !chewyItems) {
    return null
  }

  return (
    <SoapWidget
      actions={<ChewyRetailOrderStatusLabel ml="auto" statusId={statusId} />}
      classes={{
        container: classes.container,
        content: classes.content,
        title: classes.title,
      }}
      title={
        <Box width="100%">
          <Box display="flex" gap={1}>
            <ChewySubmark />
            <Typography.H2 color="chewyPrimary">{title}</Typography.H2>
          </Box>
          <Typography.Paragraph sx={{ ml: 4 }}>
            {description}
          </Typography.Paragraph>
        </Box>
      }
    >
      <Box>
        <ChargesPanel
          hideFooter
          isInvoice
          ComponentsActions={LineItemComponentsActions}
          SubHeader={SubHeader}
          chargesGroupedItems={chewyItems}
          chargesId={null}
          classes={{
            chargeInformationPanel: classes.chargeInformationPanel,
          }}
          hasHeader={false}
          invoiceId={invoiceId}
        />
        <CollapsibleInformationList
          initiallyOpened
          // width to dynamically align with invoice footer
          ContainerProps={{
            ml: 'auto',
            pr: 2,
            py: 1,
            width: 'calc(23.75% - 6px)',
          }}
          list={[
            {
              id: 'subtotal',
              label: t('Common:SUBTOTAL'),
              value: NumberUtils.formatMoney(subTotal),
            },
            {
              id: 'discount',
              label: t('Common:DISCOUNT'),
              value: NumberUtils.formatMoney(discountAmount),
            },
            {
              id: 'tax',
              label: t('Common:TAX'),
              value: NumberUtils.formatMoney(taxAmount),
            },
            {
              id: 'shipping',
              label: t('Common:SHIPPING'),
              value: NumberUtils.formatMoney(shippingAmount),
            },
            {
              id: 'retailDeliveryFee',
              label: t('Common:RETAIL_DELIVERY_FEE'),
              value: NumberUtils.formatMoney(retailDeliveryFee),
            },
          ]}
          title={t('Common:CHEWY_TOTAL')}
          titleValue={NumberUtils.formatMoney(totalPrice)}
        />
      </Box>
    </SoapWidget>
  )
}
